
.subscribe {
  h2 {
    font-size: inherit;
    font-weight: normal;
  }
  
  .image-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    margin: 0;
    padding: 0;
  }
  
  .mailchimp {
    width: 18em;
    margin-left: $lh;
    background: white;
    font-size: 0.6em;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }
  
  .subscribe-caption {
      position: fixed;
      top: $lh / 4;
      left: 1em;
      font-size: 1em;

      a {
        color: black;
        text-decoration: none;
        border-bottom: 1px solid black;
      }
  }
  
  @media only screen and (max-width: 700px) {
      .mailchimp {
          font-size: 16px;
          width: 50%;
          margin-left: 1em;
          top: 2.5em;
          transform: translate(0, 0);
      }
  }
  
  @media only screen and (max-width: 500px) {
  
    .mailchimp {
      width: calc(100% - 2em);
    }
  }
  
  .imgs {
    width: 100%;
    height: 100%;
    object-fit: cover
  }
  
  form {
    background: white;
    border-radius: 0.1em;
    box-shadow: 0 0 12px 0 rgba(0,0,0,0.1), 0 10px 30px 0 rgba(0,0,0,0.2);
    border-radius: 0.1em;
  }
  

  
  .content__gdprLegal {
    font-size: 0.7em;
  }
  
  input {
    font-size: 0.7em;
  }
  
  
  .content__gdpr {
    display: none;
  }
  
  
  .lazyload,
  .lazyloading {
   opacity: 0;
   transition: opacity 500ms;
  }
  .lazy {
    opacity: 0;
    transition: opacity 500ms;
  }
  
  img.lazyloaded {
   opacity: 1;
  }
  
  .content__gdprBlock {
      color: grey;
      a {
          border-bottom: 1px solid grey;
      }
  }
}