.newsbrief {
    padding: 0;
}

.nb {
    padding-left: $lh;
    padding-top: $lh;
    padding-bottom: $lh * 1;
    flex: 0 0 100%;
    background: #A8704A;

    @mixin media {
        padding: $lh / 2;
        padding-bottom: $lh;
    }
}

.footer {
    background: #ff00c0;
    padding-left: $lh;
    display: none;
}

.contacts {
    background: #BAA283;
    flex: 0 0 100%;
    display: flex;
    flex-wrap: wrap;

    a {
        color: black;
        text-decoration: none;
        border-bottom: 1px solid black;
    }

    @mixin media {
        padding: $lh / 2;
    }
}
.form-container {
    padding-top: $lh * 4;
    padding-bottom: $lh * 4;
    width: 100%;

    @mixin media {
        padding-top: $lh * 2;
        padding-bottom: $lh;
    }
}

.contacts-coll {
    padding-left: $lh;
    padding-top: $lh;
    padding-bottom: $lh * 3;
    flex: 0 0 25%;

    @mixin media {
        flex: 0 0 50%;
        padding: 0;
        padding-bottom: $lh;
    }
}

input {
    background: none;
    border: none;
    border-bottom: 1px solid black;
    width: 30%; 
    margin: 0;
    margin-right: $lh;
    font-size: 2em;
    font-family: Gunkan,Helvetica, Arial, sans-serif;

    @mixin media {
        width: 90%;
        padding: 0;
        margin-bottom: $lh;
    }
}

input::placeholder {
    color: #BAA283;
}

button {
    font-size: 2em;
    background: none;
    border: 1px solid black;
    padding:  $lh /2;
    width: 30%;
    font-family: Gunkan,Helvetica, Arial, sans-serif;
    
    @mixin media {
        width: 60%;
    }
}

.nb-col1 {
    display: inline-block;
    width: 100%;
    font-size: 2em;
    // color: white;
    // text-shadow: 1px 1px 6px rgba(0,0,0,0.5);

    @mixin media {
        width: 100%;
        padding: 0;
    }
}

.nb-col2 {
    display: inline-block;
    width: 100%;
    font-size: 0.8em;

    a {
        color: black;
        text-decoration: none;
        border-bottom: 1px solid black;
    }

    @mixin media {
        width: 100%;
        padding: 0;
        font-size: 0.8em;
        padding-top: $lh;
    }
}

.footer-logo {
    width: 7em;
    //display: block;
}

.footer-logo-wider {
    width: 12em;
}